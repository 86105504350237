<template>
  <v-app>
    <div v-if="session.token">
      <NavigationBar></NavigationBar>

      <v-main>
        <v-container class="px-10 py-5 mx-0" style="max-width: 1500px" fluid>
          <transition name="fade" mode="out-in">
            <router-view :key="$route.path"></router-view>
          </transition>
        </v-container>
      </v-main>
    </div>

    <div v-else>
      <AuthForm></AuthForm>
    </div>

    <v-snackbar
      v-model="$root.snackbarGlobal.snackbar"
      :timeout="$root.snackbarGlobal.snackbarTimeout"
      :multi-line="true"
      :color="$root.snackbarGlobal.snackbarColor"
      elevation="24"
    >
      {{ $root.snackbarGlobal.snackbarText }}
      <br /><span
        v-if="$root.snackbarGlobal.errorMessage !== ''"
        style="font-size: 0.7rem"
        v-html="$root.snackbarGlobal.errorMessage"
      ></span>
      <template v-slot:action="{ attrs }">
        <v-btn
          class="mx-2"
          fab
          x-small
          color="white"
          v-bind="attrs"
          @click="$root.snackbarGlobal.snackbar = false"
          elevation="0"
        >
          <v-icon small dark :color="$root.snackbarGlobal.snackbarColor">
            mdi-close
          </v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
//import AppBarNavigation from '@/components/ui/AppBarNavigation';
import NavigationBar from "@/components/ui/NavigationBar";
import AuthForm from "@/components/user/AuthForm";
import { mapGetters } from "vuex";

export default {
  components: {
    NavigationBar,
    AuthForm,
  },

  computed: {
    ...mapGetters({
      session: "auth/session",
    }),
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

header#page-header {
  display: block;
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}

#page-intro {
  display: inline-block;
  width: 100%;
  margin: 10px 0;
  font-size: 1em;
  color: gray;
  font-style: italic;
}
</style>
