<template>
  <v-container> Hallo Welt! </v-container>
</template>

<script>
import SimpleMdeEditor from "@/components/ui/SimpleMdeEditor";
export default {
  name: "Home",

  components: {
    SimpleMdeEditor,
  },

  data() {
    return {
      content: "Hallo",
    };
  },
};
</script>

<style>
@import "~simplemde/dist/simplemde.min.css";
</style>
