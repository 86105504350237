export default {

  methods: {

    zeroPad(num, places) {
      var zero = places - num.toString().length + 1;
      return Array(+(zero > 0 && zero)).join("0") + num;
    },

    addZero(i) {
      if (i < 10) {
        i = "0" + i;
      }
      return i;
    },
    
    timestampToDate(ts) {
      // Converts a timestamp to Date
      ts = parseInt(ts * 1000);
      var currentDate = new Date(ts);
      var date = this.zeroPad(currentDate.getDate(), 2);
      var month = this.zeroPad(currentDate.getMonth() + 1, 2);
      var year = currentDate.getFullYear();
      var formattedTime = date + '.' + month + '.' + year;
      return formattedTime;
    },

    formatDate(date) {
      const dt = new Date(date);
      const d = this.addZero(dt.getDate()); //Month from 0 to 11
      const m = this.addZero(dt.getMonth() + 1); //Month from 0 to 11
      const y = dt.getFullYear();
      const h = this.addZero(dt.getHours());
      const i = this.addZero(dt.getMinutes());
      //const s = this.addZero(dt.getSeconds());
      return d + "." + m + "." + y + " " + h + ":" + i + " Uhr";
    },
  
  }

};