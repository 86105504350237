import axios from "axios";

const state = {
  token: null,
  uuid: null,
  firstname: null,
  lastname: null,
  type: null,
  lang: 'de',
  currentCompany: [],
  userCompanies: [],
};

const getters = {

  session(state) {
    return state;
  },

  currentCompany(state) {
    return state.currentCompany;
  },

};

const actions = {

  async LogIn({ dispatch }, User) {
    let UserCredentials = new FormData();
    UserCredentials.append("loginName", User.get("loginname"));
    UserCredentials.append("password", User.get("password"));

    let response = await axios.post("public/login", UserCredentials);

    let credentials = {
      token: response.data.token,
      uuid: response.data.uuid
    }

    if (response.status === 200) {
      dispatch('attempt', credentials)
    }
  },


  async attempt({
    commit
  }, credentials) {


    try {

      let response = await axios.get('users/' + credentials.uuid, {
        headers: {
          'Authorization': 'Bearer ' + credentials.token
        }
      })

      const sessionData = {
        token: credentials.token,
        uuid: credentials.uuid,
        type: response.data.data.session[0].usersType,
        firstname: response.data.data.session[0].firstname,
        lastname: response.data.data.session[0].lastname,
        userCompanies: response.data.data.userCompanies,
        currentCompany: response.data.data.userCompanies[0]
      }

      commit('SET_SESSION', sessionData)

    } catch (e) {

      const sessionData = {
        token: null,
        uuid: null,
        type: null,
        firstname: null,
        lastname: null,
        userCompanies: [],
        currentCompany: []
      }

      commit('SET_SESSION', sessionData);

    }

  },

  async LogOut({ commit }) {
    let user = null;
    commit("LogOut", user);
  },

};

const mutations = {

  SET_SESSION(state, data) {

    state.token = data.token;
    state.uuid = data.uuid;
    state.type = data.type;
    state.firstname = data.firstname;
    state.lastname = data.lastname;
    state.lang = data.lang;
    state.userCompanies = data.userCompanies;
    state.currentCompany = data.currentCompany;

  },

  SET_COMPANY(state, data) {

    state.currentCompany.companyUuid = data.companyUuid;
    state.currentCompany.companyName = data.companyName;

  },

  LogOut(state) {
    state.token = null;
    state.uuid = null;
    state.type = null;
    state.firstname = null;
    state.lastname = null;
    state.userCompanies = [];
    state.currentCompany = [];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
