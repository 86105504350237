import store from "@/store"; // needed for the token
import { mapActions } from "vuex"; // import actions from vuex (eg. store/modules/auth.js)
import axios from "axios";

export default {
  methods: {
    ...mapActions({
      // spread actions in to component methods (LogIn is a method in auth.js actions)
      LogOut: "auth/LogOut"
    }),

    get(route) {
      let that = this;
      return new Promise((resolve) => {
        axios.get(route, {
            headers: {
              'Authorization': 'Bearer ' + store.getters["auth/session"].token,
            },
          })
          .then(function (response) {
            // handle success
            resolve(response);
          })
          .catch(function (error) {

            // handle error
            if (error.response.status !== 400) {
              // dont show error @ no permission
              let errorMessage = "Die API meldet einen Fehler!";
              let errorDetails = "<br/>" + error + " | Route: " + route + ". Bitte kontaktiere den SiM-Support.";

              if (error && error.response && error.response.status === 401) {
                errorMessage = "Deine Sitzung ist abgelaufen!";
                errorDetails = "<br/>Bitte logge Dich erneut ein.";
                that.LogOut();
              }

              /* start snackbar */
              that.$root.snackbarGlobal.snackbar = true;
              that.$root.snackbarGlobal.snackbarText = errorMessage;
              that.$root.snackbarGlobal.errorMessage = errorDetails;
              that.$root.snackbarGlobal.snackbarColor = "red";
              that.$root.snackbarGlobal.snackbarTimeout = 10000;
            }

            resolve(false);
          });
      });
    },

    post(route, body, data) {
      if (!body) {
        body = null;
      }

      if (!data) {
        data = null;
      }

      let that = this;
      return new Promise((resolve) => {
        axios.post(route, data, {
            headers: {
              'Authorization': 'Bearer ' + store.getters["auth/session"].token,
            }
          })
          .then(function (response) {
            // handle success
            resolve(response);
          })
          .catch(function (error) {

            let errorMessage = "Die API meldet einen Fehler!";
            let errorDetails = "<br/>" + error + " | Route: " + route + ". Bitte kontaktiere den SiM-Support.";

            if (error && error.response && error.response.status === 401) {
              errorMessage = "Deine Sitzung ist abgelaufen!";
              errorDetails = "<br/>Bitte logge Dich erneut ein.";
              that.LogOut();
            }

            /* start snackbar */
            that.$root.snackbarGlobal.snackbar = true;
            that.$root.snackbarGlobal.snackbarText = errorMessage;
            that.$root.snackbarGlobal.errorMessage = errorDetails;
            that.$root.snackbarGlobal.snackbarColor = "red";
            that.$root.snackbarGlobal.snackbarTimeout = 10000;

            resolve(false);

          });
      });
    },

    put(route, params,encoding) {
      if (!params) {
        params = null;
      }

      let contentType = 'application/x-www-form-urlencoded';
      if (encoding === 'json') {
        contentType = 'application/json';
      }

      let that = this;
      return new Promise((resolve) => {
        axios.put(route, params, {
            headers: {
              'Authorization': 'Bearer ' + store.getters["auth/session"].token,
              "Content-Type": contentType
            }
          })
          .then(function (response) {
            // handle success
            resolve(response);
          })
          .catch(function (error) {

            let errorMessage = "Die API meldet einen Fehler!";
            let errorDetails = "<br/>" + error + " | Route: " + route + ". Bitte kontaktiere den SiM-Support.";

            if (error && error.response && error.response.status === 401) {
              errorMessage = "Deine Sitzung ist abgelaufen!";
              errorDetails = "<br/>Bitte logge Dich erneut ein.";
              that.LogOut();
            }

            /* start snackbar */
            that.$root.snackbarGlobal.snackbar = true;
            that.$root.snackbarGlobal.snackbarText = errorMessage;
            that.$root.snackbarGlobal.errorMessage = errorDetails;
            that.$root.snackbarGlobal.snackbarColor = "red";
            that.$root.snackbarGlobal.snackbarTimeout = 10000;

            resolve(false);

          });
      });
    },

    patch(route, params) {

      let that = this;
      return new Promise((resolve) => {
        axios.patch(route, params, {
            headers: {
              'Authorization': 'Bearer ' + store.getters["auth/session"].token,
              "Content-Type": "application/x-www-form-urlencoded",
            }
          })
          .then(function (response) {

            // handle success
            resolve(response);
          })
          .catch(function (error) {


            let errorMessage = "Das System meldet einen Fehler!";
            let errorDetails = "<br/>" + error + " | Route: " + route + ". Bitte kontaktiere den SiM-Support.";

            if (error && error.response && error.response.status === 401) {
              
              errorMessage = "Deine Sitzung ist abgelaufen!";
              errorDetails = "<br/>Bitte logge Dich erneut ein.";
              that.LogOut();
              resolve(false);

            } else if (error && error.response && error.response.status === 409) {

              resolve(false);

            } else {

              console.error(`****** ERROR-REPORT`)
              console.error(`An error has occurred, we are sorry. So that we can fix this quickly, please contact the support directly at service@studios-in-motion.de and send the following data:`)
              console.error(`Error: ${error}`)
              console.error(`Route: ${route}`)
              console.error(`******`)
              
              /* start snackbar */
              that.$root.snackbarGlobal.snackbar = true;
              that.$root.snackbarGlobal.snackbarText = errorMessage;
              that.$root.snackbarGlobal.errorMessage = errorDetails;
              that.$root.snackbarGlobal.snackbarColor = "red"
              that.$root.snackbarGlobal.snackbarTimeout = 5000

              resolve(false);

            }

            

          })
      })
    },




    delete(route) {

      let that = this;
      return new Promise(resolve => {

        axios.delete(route, {
            headers: {
              'Authorization': 'Bearer ' + store.getters["auth/session"].token,
            }
          })
          .then(function (response) {
            // handle success
            resolve(response);

          })
          .catch(function (error) {

            let errorMessage = "Die API meldet einen Fehler!";
            let errorDetails = "<br/>" + error + " | Route: " + route + ". Bitte kontaktiere den SiM-Support.";

            if (error && error.response && error.response.status === 401) {
              errorMessage = "Deine Sitzung ist abgelaufen!";
              errorDetails = "<br/>Bitte logge Dich erneut ein.";
              that.LogOut()
            }

            /* start snackbar */
            that.$root.snackbarGlobal.snackbar = true;
            that.$root.snackbarGlobal.snackbarText = errorMessage;
            that.$root.snackbarGlobal.errorMessage = errorDetails;
            that.$root.snackbarGlobal.snackbarColor = "red"
            that.$root.snackbarGlobal.snackbarTimeout = 10000

            resolve(false);

          })
      })
    },





  }
};