import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'dashboard',
    title: 'plugins.dashboard',
    icon: 'mdi-monitor-dashboard',
    inNav: true,
    component: Home,
    meta: {
      permission: false,
      requiresAuth: false
    },
  },

  {
    path: '/mediapool',
    name: 'mediapool',
    title: 'plugins.mediapool.name',
    icon: 'mdi-folder-multiple-image',
    inNav: true,
    component: () => import('../views/mediapool/Mediapool.vue'),
    meta: {
      permission: true,
      requiresAuth: true
    },
  },

  {
    path: '/mediapool/:id',
    name: 'plugins.mediapool.:id',
    title: 'Medienpool Category',
    icon: 'mdi-folder-multiple-image',
    inNav: false,
    component: () => import('../views/mediapool/Mediapool.vue'),
    meta: {
      requiresAuth: true
    },
  },

  {
    path: '/plugins',
    name: 'plugins',
    title: 'plugins.plugins',
    icon: 'mdi-puzzle',
    inNav: true,
    hideChildren: false,
    component: {
      render: h => h('router-view')
    },

    children: [

      {
        path: '/plugins/document-maker',
        name: 'plugins.documentmaker',
        title: 'plugins.documentMaker.name',
        icon: 'mdi-file-document',
        inNav: true,
        hideChildren: true,
        component: () => import('../views/plugins/document-maker/DocumentMaker.vue'),
        meta: {
          requiresAuth: true
        },
      },

      {
        path: '/plugins/document-maker/:id',
        name: 'plugins.documentmaker.:id',
        title: null,
        icon: null,
        inNav: false,
        hideChildren: true,
        component: () => import('../views/plugins/document-maker/DocumentMakerDetail.vue'),
        meta: {
          requiresAuth: true
        },
      }

    ]
  },

  {
    path: '/frontend',
    name: 'frontend',
    title: 'plugins.frontend.name',
    icon: 'mdi-page-layout-body',
    inNav: true,
    hideChildren: false,
    component: {
      render: h => h('router-view')
    },

    children: [

      {
        path: '/frontend/rootview',
        name: 'frontend.rootview',
        title: 'plugins.frontend.rootview',
        icon: 'mdi-file-tree',
        inNav: true,
        hideChildren: true,
        component: () => import('../views/frontend/RootView.vue'),
        meta: {
          requiresAuth: true
        },
      },

      {
        path: '/frontend/category/:id',
        name: 'frontend.category.:id',
        title: null,
        icon: null,
        inNav: false,
        hideChildren: true,
        component: () => import('../views/frontend/CategoryView.vue'),
        meta: {
          requiresAuth: true
        },
      },

      {
        path: '/frontend/page/:id',
        name: 'frontend.page.:id',
        title: null,
        icon: null,
        inNav: false,
        hideChildren: true,
        component: () => import('../views/frontend/PageView.vue'),
        meta: {
          requiresAuth: true
        },
      },

      {
        path: '/frontend/forms',
        name: 'plugins.forms',
        title: 'plugins.forms.name',
        icon: 'mdi-form-select',
        inNav: true,
        hideChildren: true,
        component: () => import('../views/frontend/forms/Forms.vue'),
        meta: {
          requiresAuth: true
        },
      },

      {
        path: '/frontend/forms/:id',
        name: 'frontend.forms.:id',
        title: null,
        icon: null,
        inNav: false,
        hideChildren: true,
        component: () => import('../views/frontend/forms/Form.vue'),
        meta: {
          requiresAuth: true
        },
      },

    ]
  },

  {
    path: '/manager',
    name: 'manager',
    title: 'plugins.manager.name',
    icon: 'mdi-cog',
    inNav: true,
    hideChildren: false,
    component: {
      render: h => h('router-view')
    },

    children: [

      {
        path: '/manager/companies',
        name: 'manager.companies',
        title: 'plugins.companies.name',
        icon: 'mdi-office-building',
        inNav: true,
        hideChildren: true,
        component: () => import('../views/manager/companies/Companies.vue'),
        meta: {
          requiresAuth: true
        },
      },

      {
        path: '/manager/companies/:id',
        name: 'manager.companies.:id',
        title: null,
        icon: null,
        inNav: false,
        hideChildren: true,
        component: () => import('../views/manager/companies/Company.vue'),
        meta: {
          requiresAuth: true
        },
      },

      {
        path: '/manager/users',
        name: 'manager.users',
        title: 'plugins.users.name',
        icon: 'mdi-account-group',
        inNav: true,
        hideChildren: true,
        component: () => import('../views/manager/users/Users.vue'),
        meta: {
          requiresAuth: true
        },
      },

      {
        path: '/manager/users/:id',
        name: 'manager.users.:id',
        title: null,
        icon: null,
        inNav: false,
        hideChildren: true,
        component: () => import('../views/manager/users/User.vue'),
        meta: {
          requiresAuth: true
        },
      },

    ]
  },
  
  {
    path: "/about",
    name: "About",
    title: 'plugins.about',
    icon: 'mdi-monitor-dashboard',
    inNav: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/About.vue'),
    meta: {
      permission: false,
      requiresAuth: true
    },
  }
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {

  let authenticated = store.getters['auth/session'].token;

  if (to.matched.some((record) => record.meta.requiresAuth)) {

    if (authenticated) {
      next();
      return;
    }
    console.log("Not AUTH");
    next("/login");

  } else {
    next();
  }

});

export default router;
