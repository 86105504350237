export default {
  de: {
    plugins: {
      dashboard: "Dashboard",
      plugins: "Plugins",
      documentMaker: {
        name: "DocMaker",
        newDocument: "Ein Dokument anlegen",
        newSet: "Ein Set anlegen",
        newQuestion: "Eine Frage anlegen",
        newAnswer: "Eine Antwort anlegen",
        Questions: "Fragen",
        SetConditions: "Set-Bedingungen",
        SetConditionsQuestionInfo: "Das Set wird aktiviert, wenn die Frage ...",
        SetConditionsAnswerInfo: "... mit folgender Antwort beantwortet wurde:",
        Document: "Dokument",
        Documentinfo: "Infotext",
      },
      frontend: {
        name: "Frontend",
        rootview: "Webseite",
        createFrontendMessage: "Bislang wurde noch kein Frontend angelegt. Wenn du ein Frontend anlegen möchtest, klicke den Button.",
        createFrontendButton: "Frontend anlegen",
        createFrontendOptIn: "Wenn du tatsächlich ein Frontend anlegen möchtest, klicke auf 'Ja'",
        deleteCategoryFail: "Die Kategorie kann nicht gelöscht werden, da sich noch Seiten oder Unterkategorien darin befinden. Diese müssten zunächst gelöscht werden.",
        noCategoriesAvailable: "Es sind noch keine Kategorien vorhanden.",
        addAComponentMessage: "Ein Inhaltskomponente hinzufügen",
        Component: "Komponente",
        ReleaseAsLiveVersion: "Als Live-Version freigeben",
        CopyToDraftVersion: "In Arbeitsversion kopieren",
        ReleaseOptIn: "Möchtest Du diese Version veröffentlichen? Die bisherige Veröffentlichung wird damit überschrieben. Der Vorgang kann nicht rückgängig gemacht werden.",
        DraftOptIn: "Möchtest Du diese Version in die Arbeitsversion kopieren? Die bisherige Arbeitsversion wird damit überschrieben. Der Vorgang kann nicht rückgängig gemacht werden.",
        ReleasedFeedback: "Die Seite wurde erfolgreich veröffentlicht.",
        DraftFeedback: "Die Live-Version wurde erfolgreich in die Arbeitsversion kopiert.",
        SelectInternalLink: "Einen internen Link auswählen",
        ElementWidth: "Breite des Elements (1-12)",
        FontSize: "Schriftgröße (1-7)",
      },
      mediapool: {
        name: "Medienpool",
        UploadSafeguarding: "Ich verfüge über die Rechte diese Datei für diesen Zweck zu verwenden und versichere, dass ich mit dem Upload gegen kein geltendes Recht verstoße.",
      },
      forms: {
        fields: {
          firstname: 'Vorname',
          lastname: 'Nachname',
          email: 'E-Mail',
          phone: 'Telefon',
          mobile: 'Mobil',
          street: 'Straße',
          streetnumber: 'Hausnummer',
          city: 'Stadt',
          zipcode: 'Postleitzahl',
          country: 'Land',
          birthday: 'Geburtstag',
          gender: 'Geschlecht',
          message: 'Nachricht'
        },
        name: "Formulare",
        StandardField: "Standardfeld",
        StandardFieldInfo: "Standardfelder sind feste, vom System definierte Felder. Typ und Name können nicht verändert werden. Außerdem können Standardfelder pro Formular nur einmal verwendet werden.",
        fieldType: "Feld-Typ",
        inputType: "Input-Typ",
        AddField: "Feld",
        AddStandardField: "Standard-Feld",
      },
      manager: {
        name: "Manager",
        description: "Lorem Ipsum dolore",
      },
      companies: {
        name: "Firmen",
        description: "Lorem Ipsum dolore",
      },
      users: {
        name: "Benutzer",
        description: "Lorem Ipsum dolore",
        UsersCompanies: "Firmen des Nutzers",
        UsersPlugins: "Plugins des Nutzers",
        UserType: "Benutzer-Level",
        ReadPermission: "Lesen",
        WritePermission: "Schreiben",
        DeletePermission: "Löschen",
        usersPlugins: "Plugins des Nutzers",
        sendAccessData: "Zugangsdaten schicken",
        accessDataSended: "Zugangsdaten wurden gesendet",
      },
      about: "Über uns",
    },
    controles: {
      Safe: "Speichern",
      Edit: "Bearbeiten",
      Watch: "Anschauen",
      Duplicate: "Duplizieren",
      Export: "Exportieren",
      Delete: "Löschen",
      Preview: "Vorschau",
      TakeOver: "Übernehmen",
      Cancel: "Abbrechen",
      Close: "Schließen",
    },
    answers: {
      yes: "Ja",
      no: "Nein",
    },
    gender: {
      Mr: "Herr",
      Mrs: "Frau",
      Diverse: "Divers",
    },
    phrases: {
      Active: "Aktiv",
      Name: "Name",
      Firstname: "Vorname",
      Lastname: "Nachname",
      Email: "E-Mail",
      Usertyp: "User-Typ",
      Title: "Titel",
      CreatedDate: "Erstellungsdatum",
      oClock: "Uhr",
      Search: "Suchen",
      Searchterm: "Suchbegriff",
      by: "von",
      noPermissionsMessage: "Du hast keine Berechtigung die Daten anzuschauen.<br/>Bitte wende dich an deinen Administrator.",
      itemDeletedMessage: "Der Datensatz wurde erfolgreich gelöscht.",
      itemAddedMessage: "Der Datensatz wurde erfolgreich hinzugefügt.",
      itemUpdatedMessage: "Der Datensatz wurde erfolgreich aktualisiert.",
      companyChangedMessage: "Die Firma wurde gewechselt.",
      UserCompaniesUpdated: "Die Firmenliste des Nutzer wurde aktualisiert.",
      emailNotAvailable: "Diese E-Mail Adresse ist nicht verfügbar.",
      emailNotValid: "Das Format der E-Mail Adresse ist falsch.",
      Categories: "Kategorien",
      Category: "Kategorie",
      Pages: "Seiten",
      Page: "Seite",
      Column: "Spalte",
      Media: "Media",
      Forms: "Formulare",
      Form: "Formular",
      Description: "Beschreibung",
      Copyrights: "Urheber",
      Source: "Quelle",
      FileName: "Dateiname",
      SelectCategoryFirst: "Zunächst bitte eine Kategorie auswählen.",
      RequiredField: "Pflichtfeld",
      Name: "Name",
      ColumnWidth: "Spaltenbreite",
      Hint: "Hinweis",
      Placeholder: "Platzhalter Text",
      Label: "Label",
      FieldType: "Feld-Typ",
      businessCategoryName: "Geschäftsfeld",
      legalFormName: "Geschäftsform",
      Company: "Firma",
      UserPermissions: "Befugnisse",
      PersonalData: "Personendaten",
      Preview: "Vorschau",
      Placeholder: "Platzhalter",
      NoCategories: "Keine Kategorien vorhanden",
    },
    dialogs: {
      areYouSure: "Bist du dir sicher?",
      deleteInfo: "Du bist im begriff den Datensatz zu löschen. Der Vorgang kann nicht rückgängig gemacht werden. Wenn du dir sicher bist, klicke auf 'Ja'.",
    },
  },

  en: {
    plugins: {
      dashboard: "Dashboard",
      plugins: "Plugins",
      documentMaker: {
        name: "DocMaker",
        newDocument: "Add new document",
        newSet: "Add new set",
        newQuestion: "Add new question",
        newAnswer: "Add new answer",
        Questions: "Questions",
        SetConditions: "Set-Conditions",
        SetConditionsQuestionInfo: "The set is activated when question ...",
        SetConditionsAnswerInfo: "... has been answered with the following answer:",
        Document: "Document",
        Documentinfo: "Infotext",
      },
      frontend: {
        name: "Frontend",
        rootview: "Website",
        createFrontendMessage: "No front end has yet been created. If you want to create a frontend, click the button.",
        createFrontendButton: "Create frontend",
        createFrontendOptIn: "If you really want to create a frontend, click on 'Yes'",
        deleteCategoryFail: "The category cannot be deleted because it still contains pages or subcategories. These would first have to be deleted.",
        noCategoriesAvailable: "There are no categories yet.",
        addAComponentMessage: "Add a content component",
        Component: "Component",
        ReleaseAsLiveVersion: "Release as live version",
        CopyToDraftVersion: "Copy to draft version",
        ReleaseOptIn: "Do you want to publish this version? This overwrites the previous publication. The operation cannot be reversed.",
        DraftOptIn: "Do you want to copy this version into the working version? This overwrites the previous draft version. The operation cannot be reversed.",
        ReleasedFeedback: "The page was published successfully.",
        DraftFeedback: "The live version was successfully copied to the draft version.",
        SelectInternalLink: "Select an internal link",
        ElementWidth: "Width of the element (1-12)",
        FontSize: "Font size (1-7)",
      },
      mediapool: {
        name: "Medienpool",
        UploadSafeguarding: "I have the rights to use this file for this purpose and I assure you that I do not violate any applicable law by uploading it.",
      },
      forms: {
        fields: {
          firstname: 'First name',
          lastname: 'Last name',
          email: 'E-Mail',
          phone: 'Phone',
          mobile: 'Mobile',
          street: 'Street',
          streetnumber: 'Street number',
          city: 'City',
          zipcode: 'Postal code',
          country: 'Country',
          birthday: 'Birthday',
          gender: 'Gender',
          message: 'Message'
        },
        name: "Forms",
        StandardField: "Standard field",
        StandardFieldInfo: "Standard fields are fixed, system-defined fields. The type and name cannot be changed. In addition, standard fields can only be used once per form.",
        fieldType: "Field-Type",
        inputType: "Input-Type",
        AddField: "Field",
        AddStandardField: "Standard-Field",
      },
      manager: {
        name: "Manager",
        description: "Lorem Ipsum dolore",
      },
      companies: {
        name: "Companies",
        description: "Lorem Ipsum dolore",
      },
      users: {
        name: "Users",
        description: "Lorem Ipsum dolore",
        UsersCompanies: "Company of the user",
        UsersPlugins: "Plugins of the user",
        UserType: "User-Level",
        ReadPermission: "Read",
        WritePermission: "Write",
        DeletePermission: "Delete",
        usersPlugins: "User plugins",
        sendAccessData: "Send access data",
        accessDataSended: "Access data have been sent",
      },
      about: "About us",
    },
    controles: {
      Safe: "Safe",
      Edit: "Edit",
      Watch: "Watch",
      duplicate: "Duplicate",
      Export: "Export",
      Delete: "Delete",
      Preview: "Preview",
      TakeOver: "Take over",
      Cancel: "Cancel",
      Close: "Close",
    },
    answers: {
      yes: "Yes",
      no: "No",
    },
    gender: {
      Mr: "Mr.",
      Mrs: "Mrs.",
      Diverse: "Diverse",
    },
    phrases: {
      Active: "Active",
      Name: "Name",
      Firstname: "First name",
      Lastname: "Last name",
      Email: "E-Mail",
      Usertyp: "User-Typ",
      Title: "Title",
      CreatedDate: "Creationdate",
      oClock: "o'clock",
      Search: "Search",
      Searchterm: "Searchterm",
      by: "by",
      noPermissionsMessage: "You are not authorized to view the data.<br/>Please contact your administrator.",
      itemDeletedMessage: "The record was deleted successfully.",
      itemAddedMessage: "The record was added successfully.",
      itemUpdatedMessage: "The record was updated successfully.",
      companyChangedMessage: "The company was changed.",
      UserCompaniesUpdated: "The user's company list has been updated.",
      emailNotAvailable: "This email address is not available.",
      emailNotValid: "The format of the email address is wrong.",
      Categories: "Categories",
      Category: "Category",
      Pages: "Pages",
      Page: "Page",
      Column: "Column",
      Media: "Media",
      Forms: "Forms",
      Form: "Form",
      Description: "Description",
      Copyrights: "Copyrights",
      Source: "Source",
      FileName: "File name",
      SelectCategoryFirst: "First, please select a category.",
      RequiredField: "Required field",
      Name: "Name",
      ColumnWidth: "Column width",
      Hint: "Hint",
      Placeholder: "Placeholder text",
      Label: "Label",
      FieldType: "Field-Type",
      businessCategoryName: "Business area",
      legalFormName: "Business form",
      Company: "Company",
      UserPermissions: "Permissions",
      PersonalData: "Personal data",
      Preview: "Preview",
      Placeholder: "Platzhalter",
      NoCategories: "No other categories available",
    },
    dialogs: {
      areYouSure: "Are you sure?",
      deleteInfo: "You are about to delete the record. The operation cannot be reversed. If you are sure, click on 'Yes'.",
    },
  },
};
