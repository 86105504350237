import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueI18n from "vue-i18n";
import axios from "axios";
import translations from "./assets/translations.js"; // import the translation obj
import appData from "../package.json";
import "./assets/scss/custom.scss"; /* import custom scss */
/* import VueSimplemde from 'vue-simplemde'
import 'simplemde/dist/simplemde.min.css' */
Vue.use(VueI18n);
/* Vue.component('vue-simplemde', VueSimplemde) */

const enviroment = "dev"; // dev,prod

/* define this variable in the prototype. 
Get it via: this.$updatedMessage */

Vue.prototype.$apiBaseURL = "https://api.studios-in-motion.de/";
if (enviroment === "prod") {
  Vue.prototype.$apiBaseURL = "https://api.studios-in-motion.de/";
}

Vue.prototype.$appVersion = appData.version;
Vue.prototype.$updatedMessage = "Der Datensatz wurde erfolgreich geupdated!";
Vue.prototype.$deletedMessage = "Der Datensatz wurde erfolgreich gelöscht!";
Vue.prototype.$createdMessage =
  "Ein neuer Datensatz wurde erfolgreich angelegt!";

Vue.config.productionTip = false;

axios.defaults.withCredentials = false; // this is needed because by default cookies are not passed by Axios.

axios.defaults.baseURL = "https://api.studios-in-motion.de/";
if (enviroment === "prod") {
  axios.defaults.baseURL = "https://api.studios-in-motion.de/";
}

const messages = translations; // should have been messages
const i18n = new VueI18n({
  locale: store.getters["auth/session"].lang, // get locale from store
  fallbackLocale: "de",
  messages, // set locale messages
});

new Vue({
  router,
  store,
  vuetify,
  i18n,
  data: {
    snackbarGlobal: {
      snackbar: false,
      snackbarText: null,
      snackbarColor: "primary",
      snackbarTimeout: 2000,
      errorMessage: null,
    },
  },
  render: (h) => h(App),
}).$mount("#app");
