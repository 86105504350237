<template>
  <v-container>
    <textarea
      id="simplemdearea"
      ref="area"
      sanitize
      style="width: 100%"
    ></textarea>
  </v-container>
</template>

<script>
import simplemde from "simplemde"; // import from npm package
import "simplemde/dist/simplemde.min.css";
export default {
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  components: {},
  data() {
    return {
      mde: "",
      that: null,
    };
  },

  mounted() {
    this.mde = new simplemde({
      element: this.$refs.area,
      status: false,
      spellChecker: false,
      sanitize: true,
      toolbar: [
        "bold",
        "italic",
        "heading-1",
        "heading-2",
        "heading-3",
        "unordered-list",
        "ordered-list",
        "link",
        "quote",
      ],
    });
    this.mde.value(this.value);
    var self = this;
    this.mde.codemirror.on("change", function () {
      self.$emit("input", self.mde.value());
    });
  },
  watch: {
    value(newVal) {
      if (newVal != this.mde.value()) {
        this.mde.value(newVal);
      }
    },
  },
  beforeDestroy() {
    this.mde.toTextArea();
  },
};
</script>
