<template>
  <div v-if="!fetchinData">
    <v-select
      v-if="companyChangeOption"
      class="m-0"
      :items="items"
      v-model="companyID"
      item-text="name"
      item-value="uuid"
      :label="$t('phrases.Company')"
      @change="changeCompany"
      filled
      dense
      hide-details
    ></v-select>

    <v-select
      v-else
      class="m-0"
      :items="items"
      v-model="companyID"
      item-text="name"
      item-value="uuid"
      :label="$t('phrases.Company')"
      filled
      disabled
      dense
      hide-details
    ></v-select>
  </div>
</template>

<script>
import store from "@/store";
import api from "@/mixins/api";
import helpers from "@/mixins/helpers";
export default {
  name: "CompanySwitcher",
  components: {},
  data() {
    return {
      companyChangeOption: true,
      items: [],
      auth: [],
      companyID: store.getters["auth/session"].currentCompany.companyUuid,
      fetchinData: false, // should be true when fetching data
    };
  },

  props: [],
  created: function () {
    this.getData();
  },

  methods: {
    async getData() {
      this.fetchinData = true;
      let response = await this.get(`manager/companies`); // await data from mixin
      this.items = response.data.data;
      this.auth = response.data.auth;
      this.fetchinData = false;
    },

    async changeCompany() {
      // tell server about the new company

      const params = new URLSearchParams();
      params.append("currentCompany", this.companyID);

      this.preloader = true;
      let response = await this.patch(`/session/changeCompany`, params);
      this.preloader = false;

      const data = {
        companyName: response.data.data[0].name,
        companyUuid: this.companyID,
      };
      this.$store.commit("auth/SET_COMPANY", data);

      /* start snackbar */
      this.$root.snackbarGlobal.snackbar = true;
      this.$root.snackbarGlobal.snackbarText = this.$t(
        "phrases.companyChangedMessage"
      );
      this.$root.snackbarGlobal.snackbarColor = "green";
      this.$root.snackbarGlobal.snackbarTimeout = 5000;
    },
  },

  computed: {},

  mixins: [api, helpers],
};
</script>
